.clickable:hover {
  cursor: pointer;
}

.image-button-top-right {
  position: absolute;
  right: 0
}

.image-button-top {
  width: inherit;
  display: flex;
}

.project-agora-link-gray {
  text-decoration: none;
  color: gray;
}

.in-article-light-wrapper {
  
}


.in-article-light-top {
  text-decoration: none;
  text-align: center;
  font-size: 0.7em;
  line-height: 30px;
  z-index: 15;
}


.in-article-light-image {
  position: absolute;
  z-index: -5;
  width:100%;
  object-fit: contain;
}

#creative-overlay-in-article {
  background-color: white;
  width: 300px;
  top: 76px;
  z-index: 2;
  transform: translateX(0px);
}

.video-percentage {
  height: 5px;
  background-color: orange;
  width:0;
}

.video-percentage-wrapper {
  height: 5px;
  background-color: gray;
  position: absolute;
  width: inherit;
  bottom: 0px;
}


.video-buttons-top-right {
  position: absolute;
  right: 0
}

.video-buttons-top {
  position: absolute;
  top: 0;
  width: inherit;
  display: flex;
}

.video-buttons-bottom-right {
  position: absolute;
  right: 0px;
}

.video-buttons-bottom {
  position: absolute;
  bottom: 5px;
  width: inherit;
  display: flex;
}

.svg-button:hover {
  cursor: pointer;
}

.in-article-placement {
  
}

.creative-overlay-in-article-hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.creative-overlay-in-article-show {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 1;
  visibility: visible;
  height: auto;
}

#display-ad-anchor {
  width: 100%;
  height: 1px;
  left: 0;
}

.in-article-video {
  width: inherit;
}
