.hashtags-leica a {
  text-decoration: none;
}

.hashtags-leica {
  margin-left: 1em;
}

#creative-overlay-social-cards {
  background-color: white;
  width: 295px;
  top: 76px;
  z-index: 2;
}

.social-cards-instagram-card hr {
  background-color: #dddfe2;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.social-cards-instagram-card {
  border: 1px solid #dddfe2;
  border-radius: 1px;
}

.social-cards-text p {
  margin-left: 10px;
  margin-right: 5px;
}

.social-cards-text p a {
  text-decoration: none;
}

.icon-right {
  float: right;
}

.social-cards-text img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.social-cards-text {
  font-size: 15px;
}

.social-cards-icon2 {
  height: 20px;
  width: 20px;
  float: right;
  margin-right: 15px;
}

.social-cards-instagram-card-header b {
  margin-left: 15px;
}

.social-cards-instagram-card-header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.social-cards-icon1 {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  float: left;
}


.social-cards-top {
  height:32px;
  text-align: center;
  font-size: 10px;
  line-height: 30px;
  z-index: 15;
}

.social-cards-top a {
  text-decoration: none;
  color: #747474;
}

.social-cards-top a:hover {
  color: #d84820;
  font-size: 11px;
}

.social-cards-video {
    width: 100%;
}

.agora-social-ancor-container a {
  text-decoration: none;
  padding: 18px 15px 12px 15px;
  background: #0090d2;
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  box-shadow: none;
  height: 35px;
  transition: background 0.2s;
  font-weight: bold;
  text-align: left;
  display: block;
  color: #fff!important;
}

