.photo-3-tooltip {
  position: absolute;
  bottom: 7em;
  width: 20em;
  left: calc(50% - 10em);
  text-align: center;
  color: white;
}

.shop-now-button {
  position: absolute;
  bottom: 3em;
  left: calc(50% - 4em);
  height: 3em;
  line-height: 2.8em;
  width: 8em;
  border-radius: 2em 2em 2em 2em;
  background-color: #019CEA;
  color: white;
  text-align: center;
  text-decoration: none;
}

.product-description {
/*  display: none;*/
  font-size: 0.8em;
  margin-bottom: 1em;
}

.hidden {
  display: none;
}

.buy-now-tooltip-active {
  border-radius: 0.8em 0.8em 0.8em 0.8em;
  padding: 0.8em 1em .8em 1em;
  transform: translate(-0.4em, -0.5em);
  background-color: white;
  color: black;
  cursor: pointer;
  position: absolute;
}

.buy-now-button-clicked {
  margin-top: 1.5em;
  border-radius: 0.8em 0.8em 0.8em 0.8em;
  padding: 0.3em 0.5em 0.4em 0.5em;
  background-color: #019CEA;
  color: white;
  text-align: center;
  text-decoration: none;
}

.buy-now-button {
  color: #0099ff;
  text-decoration: none;
}

.buy-now-tooltip:hover {
  cursor: pointer;
}

.buy-now-tooltip {
  color: white;
  background-color: #565656;
  position: absolute;
  border-radius: 0.5em;
/*  text-align: center;*/
  padding: 0.5em 0.5em 0.5em 0.5em;
}

.creative-percentage-bar {
  height: 3px;
  width: 0px;
  background-color: #8e9094;
  border-radius: 5px;
}

.creative-percentage-bar-wrapper {
  position: absolute;
  top: 8px;
  width: 100%;
  height: 3px;
  background-color: #ced2d9;
  border-radius: 5px;
}

.swapping-content {
  height: 100%;
  width: inherit;
  margin: auto;
  display: grid;
}

.creative-overlay-magic-story {
  background-color: white;
}
