:root {
  --mobile-preview-width: 310px;
  --creative-overlay-height: 582px;
  --creative-overlay-width: 295px;
  --mobile-preview-height: 580px;
  --paragraph-height: 140px;
  --overlay-bottom-height: 6em;
  --overlay-top-height: 3em;
  --video-height: 580px;
  --video-width: 320px;
  --video-offset-top: 5vh;
}

html {
  overflow: hidden;
}

.wrapper {
 margin: auto;
 width: var(--mobile-preview-width);
}


.paragraph {
  background-color: white;
  height: var(--paragraph-height);
  position: relative;
  z-index: 1;
  width: inherit;
}

.mobile-wireframe {
  position: absolute;
  width: 372px;
  height: 713px;
  z-index: -5;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select:none;
  user-select:none;
  -o-user-select:none;
}

#creative-top-anchor{
  width: 100%;
  height: 1px;
  left: 0;
}

#creative-mid-anchor{
  width: 100%;
  height: 1px;
  margin-top: calc(var(--creative-overlay-height)/2);
  position: absolute;
}

#creative-bottom-anchor {
  width: 100%;
  height: 1px;
  left: 0;
}

.mock-headers {
  z-index: 1;
  position: relative;
  width: inherit;
}

.mock-paragraph-title {
  border-radius: 1px;
}

.mock-paragraph-line {
  border-radius: 1px;
}

.mock-page {
  display: grid;
  position: sticky;
  z-index: 1;
  width: inherit;
}

.mobile-preview {
  height: var(--mobile-preview-height);
  display: inline-block;
  margin: 77px 0 0 31px;
  position: absolute;
  width: 310px;
  overflow-x: clip;
  overflow-y: auto;
  z-index: 1;
}


