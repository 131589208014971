.svg-button-vertigo-horizontal {
  color: gray !important;
}

.svg-button {
  z-index: 0;
  position: relative;
}
.svg-button:hover {
  cursor: pointer;
}

.vertigo-horizontal-video {
  width: 100%;
  position: absolute;
  top: 250px;
}

.vertigo-horizontal-buttons-top-right {
  position: absolute;
  right: 0
}

.vertigo-horizontal-buttons-top {
  position: absolute;
  top: 240px;
  width: inherit;
  display: flex;
}

.vertigo-horizontal-image {
  position: absolute;
  width: 200px;
  top: 80px;
  left: calc(50% - 100px);
}

.vertigo-light-image {
  position: absolute;
  z-index: -5;
  width:100%;
  height:100%;
  object-fit: cover;
}

#creative-overlay {
  background-color:black;
  position: fixed;
  height: var(--creative-overlay-height);
  width: var(--creative-overlay-width);
  top: 76px;
  z-index: -1;
}

.creative-overlay-horizontal {
  background-color: #F7EDD1 !important;
}

.creative-placement-holder {
  position: relative;
  top: -1px;
  z-index: -5;
  width: calc(-15px + 100vw);
  height: 600px;
}

.creative-learn-more-button:hover {
  color: white;
  cursor: pointer;
}

.creative-learn-more-button-vertigo-horizontal {
  display: inline-block !important;
  border: 2px solid gray !important;
  border-radius: 10px 0px 10px 0px !important;
  color: gray !important;
  background-color: white;
  text-decoration: none;
  right: 1em;
  border: 2px solid white;
  border-radius: 10px;
  width: 8em;
  height: 3em;
  line-height: 2.5em;
  cursor: pointer;
  color: white;
  display: inline;
  z-index: 0;
}

.creative-learn-more-button {
  position: absolute;
  text-decoration: none;
  right: 1em;
  border: 2px solid white;
  border-radius: 10px;
  width: 8em;
  height: 3em;
  line-height: 2.5em;
  color: white;
  display: inline;
  z-index: 0;
}

.creative-bottom-buttons-overlay {
  margin-bottom: 0.5em; /* creative-bottom-overlay margin-bottom */
  margin-left: 2em;
  display: flex;
  z-index: -1;
}

.creative-bottom-pa {
  background: rgba(79, 79, 79, 0.8);
  height: 3em;
  bottom: 0;
  z-index: -1;
}

.creative-bottom-overlay-light {
  position: absolute;
  bottom: 0px;
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 3em;
  width: 100%;
  z-index: -1;
}

.creative-bottom-overlay {
   /* margin-bottom: 0.5em; creative-bottom-buttons-overlay margin-bottom */
  position: absolute;
  bottom: 0px;
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 3em;
  width: 100%;
  /* height: var(--overlay-bottom-height); */
  z-index: -1;
}

.creative-top-overlay {
  color: white;
  text-align: center;
  font-size: 15px;
  line-height: 3em;
  background: rgba(79, 79, 79, 0.8);
  z-index:-1;
  height: var(--overlay-top-height);
}

.vertigo-video {
  position: absolute;
  z-index: -5;
  width:100%;
  height:100%;
  object-fit: cover;
}
