#creative-overlay-in-storyboard {
  background-color: white;
  width: 300px;
  top: 76px;
  z-index: 2;
  transform: translateX(0px);
}

.video-percentage-storyboard {
  height: 3px;
  background-color: #f2623d;
  width:0;
}

.video-percentage-storyboard-wrapper {
  position: absolute;
  height: 3px;
  background-color: rgba(0,144,210,1);
  max-width: 240px;
  bottom: 0px;
  top: 132px;
  width: 100%;
  z-index: 5;
}

.video-buttons-storyboard-bottom {
  display: flex;
  justify-content: space-between;
  top: 92px;
  width: 100% ;
  position: absolute;
  z-index: 5;
}

.svg-button-storyboard:hover {
  cursor: pointer;
}

.creative-overlay-in-storyboard-hide {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.creative-overlay-in-storyboard-show {
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 1;
  visibility: visible;
  height: auto;
}

#display-ad-anchor-storyboard {
  width: 100%;
  height: 1px;
  left: 0;
}

.storyboard-top {
  height:32px;
  text-align: center;
  font-size: 10px;
  line-height: 30px;
  z-index: 15;
}

.storyboard-top a {
  text-decoration: none;
  color: #747474;
}

.storyboard-top a:hover {
  color: #d84820;
  font-size: 11px;
}

#storyboard-container {
  max-width: 371px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.storyboard-header {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  display: flex;
  justify-content: space-around;
}

.storyboard-logo-img {
  width: 15%;
  height: 45px;
  /* margin-right: 4px;
  margin-left: 13px; */
}

.storyboard-title-text {
  color: #000;
  font-size: 1.2em;
  padding-bottom: 6px;
  padding-top: 2px;
}

.storyboard-title-empty-div {
  width: 35px;
}

.storyboard-hastag-text {
  color: #333;
  font-size: 0.8em;
  padding-bottom: 6px;
  padding-top: 2px;
}

#storyboard-carousel-container {
  position: relative;
  background: #fff;
}

.storyboard-img-carousel {
  max-width: 240px;
  opacity: 1;
  width: 100%;
  height: 135px
}

.storyboard-img-container {
  border: 1px solid #bdbdbd;
  max-width: 240px;
  vertical-align: middle;
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-bottom: 10px;
}

.storyboard-below-img-btn {
  margin: 1px auto 0px;
  padding: 6px 0px 5px 0px;
  color: #fff;
  text-align: center;
  z-index: 3;
  width: fit-content;
  max-width: 230px;
}

.storyboard-below-video-btn {
  margin: 1px auto 0px;
  padding: 0px 0px 5px 0px ;
  color: #fff;
  text-align: center;
  z-index: 3;
  width: fit-content;
  max-width: 230px;
}

.img-btn {
  background-color: #f6f7f8;
  background-repeat: repeat-x;
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  box-sizing: content-box;
  font-weight: bold;
  text-align: center;
  border-color: #cccccc #c5c6c8 #b6b7b9;
  color: #4e5665;
  text-shadow: 0 1px 0 #fff;
  font-size: 0.8em;
  padding: 5px 2px 5px 2px;
  cursor: pointer;
  text-decoration: none;
  z-index: 3;
}

/* Carousel */
.carousel-indicators {
  z-index: 0 !important;
}

.carousel-indicators button {
  margin-top: 100px; 
  visibility: hidden; 
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 35px !important;
  width: 35px !important;
  background-size: 90%, 90% !important;
  outline: white !important;
  border-radius: 50%;
  border: 4px solid #c1c1c1 !important;
  background-color: #c1c1c1 !important;
  
}

.carousel-control-prev-icon:focus,
.carousel-control-next-icon:focus {
  opacity: 0.5 !important;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  opacity: 0.5 !important;
}

.carousel-control-next span {
  opacity: 0.5 !important;
}
.carousel-control-prev span {
  opacity: 0.5 !important;
}
/* Carousel end */

.video-buttons-storyboard-bottom-left {
  padding-left: 5px;
}

.video-buttons-storyboard-bottom-left div svg{
  width: 25px ;
  color: #bdbdbd;
}

.video-buttons-storyboard-bottom-right {
  padding-right: 5px;
  display: flex;
}

.video-buttons-storyboard-bottom-right-fullscreen svg{
  width: 25px ;
  color: #bdbdbd;
}

.video-buttons-storyboard-bottom-right-sound {
  padding-right: 5px;
}

.video-buttons-storyboard-bottom-right-sound svg{
  width: 25px ;
  color: #bdbdbd;
}

/* Safari */
:-webkit-full-screen {
  height: 100vh;

}

/* IE11 syntax */
:-ms-fullscreen {
  height: 100vh;
}

/* Standard syntax */
:fullscreen {
  height: 30%;
}